import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"

import Jumbotron from "react-bootstrap/Jumbotron"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const RuntMustigt = () => {
  const data = useStaticQuery(graphql`
    query SiteRuntQuery {
      img: file(relativePath: { eq: "espresso.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout hero={false}>
      <SEO title="Runt & Mustigt" />
      <Img fluid={data.img.childImageSharp.fluid} />
      <Row>
        <Col>
          <h1 className="pt-3">Runt & Mustigt</h1>
          <p>Härligt mustigt men samtidigt mjukt</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Upplev kvalitet utan dess like, kanske tillsammans med en vän under
            en filt en mysigt men regning höstdag.
          </p>

          <p>
            Some quick example text to build on the card title and make up the
            bulk of the card's content. Some quick example text to build on the
            card title and make up the bulk of the card's content.
          </p>

          <p>
            Some quick example text to build on the card title and make up the
            bulk of the card's content. Some quick example text to build on the
            card title and make up the bulk of the card's content. Some quick
            example text to build on the card title and make up the bulk of the
            card's content. Some quick example text to build on the card title
            and make up the bulk of the card's content.
          </p>
        </Col>
        <Col md lg={4}>
          <Jumbotron className="py-3 px-4">
            <p>En smakresa för endast 299 SEK</p>

            <ul>
              <li>I brevlådan en gång varje månad, i 3 månader</li>
              <li>Nyrostade bönor</li>
              <li>Specialkaffe av högsta kvalitet</li>
              <li>Optimalt odlat</li>
              <li>Producerat med bra villkor</li>
            </ul>
          </Jumbotron>

          <button
            className="snipcart-add-item btn btn-outline-secondary"
            data-item-id="espresso"
            data-item-name="Runt & mustigt"
            data-item-price="299"
            data-item-image={data.img.childImageSharp.fluid.src}
            data-item-url="/"
          >
            Köp Runt & Mustigt
          </button>

          <p className="small my-3">
            <Link to="/">Läs mer om våra andra paket &raquo;</Link>
          </p>
        </Col>
      </Row>
      <Works />
    </Layout>
  )
}

export default RuntMustigt
